﻿.container {
    width: 90vw;
    background: white;
    margin: 15px auto;
    padding: 10px 15px;
    min-height: 80vh;
}

.backButton {
    font-weight: bold;
    cursor: pointer;
    color: forestgreen !important;

    &:active, &:hover {
        text-decoration: none;
        color: forestgreen;
    }
}
