@import "../../Global.scss";

:global(.rs-table-row){
  cursor: pointer;
}

.locationSelect, .deviceSelect{
    flex: 1;
    margin: 15px;
}

.bottom{
    display: flex;
}

.rightPane{
    position: fixed;
    width: 40%;
}