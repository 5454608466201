@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";

footer {
    height: 20px;
}

body {
    padding-bottom: 15px;
    background-color: #f1f3f6;
    font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    margin: 0;
    box-sizing: border-box;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}

.h1, .h2, .h3, h1, h2, h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}

.bottom-gutter, form, h1 {
    margin-bottom: 15px;
}

h1 {
    font-size: 18px;
}

.messages, .results, .search {
    transition: .2s ease-in-out;
}

.row.frame {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #fff;
    border: 1px solid #e4e5e7;
}

a {
    color: #0066B3;
}

.nav-pills .nav-link.active {
    background-color: #0066B3;
}

.buttonLink {
    background: none;
    border: none;
    padding: 0;
    color: #069;
    cursor: pointer;
}

.right-menu {
    margin-left: auto;
}