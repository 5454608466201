.editTable {
    margin: 10px 0px;

    td {
        margin: 5px;
        padding: 10px;

        label {
            margin-bottom: 0px;
            line-height: 52px;
            text-align: left;
            font-size: 14px;
            font-weight: 400;
        }
    }
}

.dropdown{
    width: 400px;
}

.bigText{
    width: 800px;
}