.loading {
    opacity: 0.3;
}

.border-md {
    border-width: 2px;
}

.cursor-pointer {
    cursor: pointer;
}

.color-img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.color-img-sm {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.progress-sm {
    height: 0.2rem;
    border-radius: 0;
}
