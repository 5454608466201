@import "../../Global.scss";

.customTable{
    width: 40vw;
}

.newUser{
    width: 25vw;

    input{
        margin: 5px;
    }
}