.navbar-no-padding {
    padding: 0;
}

.logo {
    height: 50px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1030;
}

nav {
    display: block;
    background-color: #00963e;
    border: 1px solid transparent;
    border-color: #00963e;
    border-radius: 0;
    margin-bottom: 0;
    border-width: 0 0 1px;
    min-height: 50px;
    width: 100%;
}

div#navbar > ul.nav > li.nav-item > a.nav-link,
div#navbar > ul.nav > li.nav-item > button.nav-link {
    color: white;
    padding: 10px 15px;
    cursor: pointer;
}

@media (min-width: 768px) {
    div#navbar > ul.nav > li.nav-item > a.nav-link,
    div#navbar > ul.nav > li.nav-item > button.nav-link {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.navbar-collapse .navbar-nav > .nav-item > a.active,
.navbar-collapse .navbar-nav > .nav-item > a.active:focus,
.navbar-collapse .navbar-nav > .nav-item > a.active:hover,
.navbar-collapse .navbar-nav > .nav-item > button.active,
.navbar-collapse .navbar-nav > .nav-item > button.active:focus,
.navbar-collapse .navbar-nav > .nav-item > button.active:hover {
    background-color: #14CC60;
}

.navbar-collapse .navbar-nav > li > a:focus,
.navbar-collapse .navbar-nav > li > a:hover,
.navbar-collapse .navbar-nav > li > button:focus,
.navbar-collapse .navbar-nav > li > button:hover {
    background-color: #3BCEAC;
}

ul.nav {
    width: 90vw;
}

button.navbar-toggler {
    margin-left: 15px;
    margin-right: auto;
}

button.navbar-toggler {
    margin-top: 5px;
    margin-bottom: 5px;
}

li.nav-item {
    cursor: pointer;
}