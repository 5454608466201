.removeButton{
    color: red;
    cursor: pointer;
}

.usercodeTable {
    margin-bottom: 15px;
    width: 50vw;

    tr.deletionRow{
        opacity: 0.5;
    }

    td, th {
        padding: 5px;

        &.codeEdit {
            width: 100px;
        }
    }
}
