@import "../../Global.scss";

:global(.rs-table-row){
  cursor: pointer;
}

.signature{
    width: 200px;
}

.showImages{
    cursor: pointer;
    font-weight: bold;
}

.showAllContainer{
    margin: 10px 5px;
}

:global(.e-acrdn-item) {
    border: 1px solid black !important;
}

.highlight {
    width: 1000%;
    background: #FFBABA;
    position: relative;
    margin-left: -15px;
    padding-left: 15px;
    color: #D8000C;
}

.tableResults {
    margin: 15px 0px;
    border: 1px solid black;

    td, th {
        padding: 5px;
    }

    td {
        border: 1px solid black;
    }

    th {
        background: forestgreen;
        color: white;
    }
}