.logoContainer{
    text-align: center;
}

.loginContainer {
    margin-top: 30px;
    width: 30vw;
    margin-left: auto;
    margin-right: auto;

    > div {
        margin: 8px;
    }
}

.loaderContainer{
    width: 50px;
}


.customerLoginButton {
    text-align: right;

    > a {
        font-weight: bold;
        cursor: pointer;
        color: forestgreen !important;

        &:active, &:hover {
            text-decoration: none;
            color: forestgreen;
        }
    }
}
